import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

/**
 *@ Materials
 */
import {
  RESPONSIVE_STYLES,
  BlogCategoriesJa,
  BlogCategoryColors,
} from "../../AppConst"
import { WPFrontMatter } from "../../types/WPFrontMatter"
import { formateDate } from "../../utlis/formatDate"

/**
 *@ Elements & Style
 */
const StyledLink = styled(Link)`
  display: flex;
  width: 100%;
  text-decoration: none;
  color: #000;
  background-color: #F4F4F4;

  &:hover {
    opacity: .7;
    h3 {
      text-decoration: underline;
    }
  }

  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 272px;
    flex-wrap: wrap;
    gap: 1.6rem;
  }
`
const ImageWrapper = styled.div`
  width: 510px;
  overflow: hidden;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 272px;
    margin-right: 0;
  }
  & Img {
    width: 100%;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 288px;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #F4F4F4;
  gap: 8px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
    height: 196px; // 全記事にカテゴリがあれば設定不要の想定
    padding: 16px;
  }
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: auto;
`

const Category = styled.span<{ categoryColor: string }>`
  width: 100%;
  position: relative;
  padding-left: 16px;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
  }
  &::before {
    content: '';
    width: max(1em, 12px);
    height: max(1em, 12px);
    background-color: ${props => props.categoryColor};
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
`

const Date = styled.span`
  width: 100%;
  font-family: 'Righteous';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #7A7A7A;
`

const Title = styled.h3`
  height: 72px;
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.08rem;
  text-align: left;
  color: #222222;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`

const DescriptionWrapper = styled.p`
  height: 116px;
  box-sizing: border-box;
  margin: 0;
  padding: 16px;
  background-color: #FFF;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    display: none;
  }
`

const Description = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: #222222;
`

const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  z-index: 1;
`

const Writer = styled.h4`
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #222222;
`

/**
 *@ Types
 */
type Options = {
  marginTop: string
}
type State = {
  frontMatter: WPFrontMatter
  member: any
}
type Props = State & Partial<Options>


/**
 *@ ReactComponents
 */
const ArticleSliderCard: React.FC<Props> = ({ frontMatter, member }: Props) => {
  const { title, description } = frontMatter;

  return (
    <StyledLink to={"/" + frontMatter.slug}>
      <ImageWrapper>
        <img src={frontMatter.thumbnail} className='thumbnail' />
      </ImageWrapper>
      <ContentWrapper>
        <Date>{formateDate(frontMatter.date)}</Date>
        <Category categoryColor={BlogCategoryColors[frontMatter.category]}>{BlogCategoriesJa[frontMatter.category]}</Category>
        <Title>{title}</Title>
        <DescriptionWrapper>
          <Description>{description}</Description>
        </DescriptionWrapper>
        <Footer>
          <Avatar src={`/images/${member.filename}`} />
          <Writer>{member.name}</Writer>
        </Footer>
      </ContentWrapper>
    </StyledLink>
  )
}

export default ArticleSliderCard
