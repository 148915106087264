import React, { useMemo } from "react";
import { Link } from "gatsby";
import { merge } from 'lodash';
import styled from 'styled-components';
import { RESPONSIVE_STYLES } from "../../../AppConst"

/**
 * Elements & Styles
 */
const Node = styled.div`
  display: flex;
  align-items: flex-end;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin-bottom: auto;
  }
`

const Text = styled.span<{ isOpen: boolean }>`
  font-family: 'futura-pt';
  font-size: 1.6rem;
  font-weight: 500;
  margin-left: 16px;
  color: ${({ isOpen }) => isOpen && `#fff` };
  transition: 0.5s;
`

const StaticImg = styled.img`
`;

const StyledLink = styled(Link)<{ isOpen: boolean }>`
  width: 80px;
  height: 80px;
  border-radius: 0 0 10px 10px;
  background-color: ${({ isOpen }) => isOpen ? `#fff` : `#222` };
  text-align: center;
  & .brand-img {
    margin: 24px auto;
    max-width: 32px;
    max-height: 32px;
    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      margin: 14px auto;
      max-width: 20px;
      max-height: 20px;
    }
  }
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 48px;
    height: 48px;
  }
`
/**
 * Type
 */
type Options = {
  flex: string;
}
type State = {
  slug?: string;
  img: any;
  isOpen: boolean;
}
type Props = State & Partial<Options>

/**
 * default
 */
const defaultStyle = () => ({
    flex: '192px'
});

const Brand: React.FC<Props> = (props) => {

  const options = useMemo(() => merge(defaultStyle(), {
    flex: props.flex
  }), [
    props.flex
  ]);
  return (
    <Node style={options}>
      <StyledLink to={props.slug} isOpen={props.isOpen}>
        <StaticImg className='brand-img' src={props.img} alt='' />
      </StyledLink>
      <Text isOpen={props.isOpen}>Nextribe Inc.</Text>
    </Node>
  ); 
}

export default Brand
