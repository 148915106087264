import React from 'react'
import styled from 'styled-components'

import { IMainHeaderItem, ISubHeaderItem } from '../../../types/IHeaderItem';

import { SubHeaderItemAllList } from './SubHeaderItemAllList';

const Node = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  :after {
    content: '';
    width: 30%;
  }
`
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 30%;
  & .underline {
    width: 100%;
    border-bottom: 2px solid #FFFFFF;
  }
`

const Title = styled.span`
  display: flex;
  align-items: center;
  gap: 14px;
  & h2 {
    font-family: 'Righteous';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: left;
    letter-spacing: 0.1em;
    color: #61B1C1;
  }
`

type State = {
  mainNavItems: IMainHeaderItem[];
  subNavItems: { [key: string]: ISubHeaderItem[] };
}

type Props = State;

export const SubHeaderAllItems: React.FC<Props> = (props) => {
  const { mainNavItems, subNavItems } = props;
  return (
    <Node>
      {mainNavItems.map((item, index) => {
        const subItems = subNavItems[item.slug];
        return (
          <Container key={index}>
            <div className='underline'>
              <Title>
                <h2>
                  {item.titleEn}
                </h2>
              </Title>
            </div>
            <SubHeaderItemAllList subNavItems={subItems} />
          </Container>
        )})
      }
    </Node>
  )
}
