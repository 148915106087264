import React, { useMemo } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

/**
* @ Elements & Styles
*/

const StyledLink = styled(Link)`
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 8px 0;
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  position: relative;
  text-decoration: none;
  &:hover {
    color: #61B1C1;
    text-decoration-line: underline;
    ::after {
      background-color: #61B1C1;
    }
  }
`

/**
* @ Props
*/
export type SubCategoryItems = {
  item: string
  slug: string
}

type Props = SubCategoryItems;

/**
* @ Component
*/
const FooterCategoryListItem: React.FC<Props> = props => {
  return (
    <StyledLink to={"/" + props.slug} className="footer_list-item">
      {props.item}
    </StyledLink>
  )
}

export default FooterCategoryListItem
