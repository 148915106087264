import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { ISubHeaderItem } from "../../../types/IHeaderItem"

/**
 * Materials
 */
import SubHeaderItem from "./SubHeaderItem"
import { IHeaderNavActions } from "./IHeaderNavActions"
import { TriangleSwitch } from "../TriangleSwitch"

/**
 * Element & Style
 */
const Node = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  column-gap: 48px;
  & .subheader__nav__item:last-child::after {
    width: 0;
  }
  & .box:hover{
    background-color: #444444;
    border-bottom: 2px solid #61B1C1;
    & .subheader__nav__item {
      color: #61B1C1;
    }
    & .mark {
      margin-right: 22px;
    }
  }
`

const Wrapper = styled(Link)`
  width: calc((100% - 100px) / 3);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 24px 0px;
  border-bottom: 2px solid #FFFFFF;
  justify-content: space-between;
  text-decoration: none;
  color: #ffffff;
`

const TriangleWrapper = styled.div`
  transition: 0.3s;
  margin-right: 30px;
  transform: rotate(-90deg);
`

/**
 * Types
 */
type Options = {
  actions: IHeaderNavActions;
}
type State = {
  items: ISubHeaderItem[]
}

type Props = State & Partial<Options>

/**
 * ReactComponent
 */
const SubHeaderBlogItemList: React.FC<Props> = props => {
  return (
    <Node>
      {props.items.map((item, index) => {
        return (
          <Wrapper key={index} className="box" to={"/" + item.slug}>
            <SubHeaderItem key={`${item}/${index}`} slug={item.slug} value={item.value} />
            <TriangleWrapper className="mark">
              <TriangleSwitch color="#61B1C1" />
            </TriangleWrapper>
          </Wrapper>
        )
      })}

      { /* 01ECのタグリンクを固定で追加し、カテゴリのようにする */ }
      {/* {
        // props.items.length > 0 && props.items[0].slug == 'blog' &&
          <Wrapper className="box" to={"/tags/0%E2%86%921%20ENGINEER%20CAMP/"}>
            <SubHeaderItem slug={"/tags/0%E2%86%921%20ENGINEER%20CAMP/"} value={"超実践型エンジニア育成"} />
            <TriangleWrapper className="mark">
              <TriangleSwitch color="#61B1C1" />
            </TriangleWrapper>
          </Wrapper>
      } */}
    </Node>
  )
}

export default SubHeaderBlogItemList
