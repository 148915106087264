import React from "react"
import styled from "styled-components"

const Wrapper = styled.div<{ isSmall: boolean }>`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  p {
    display: flex;
    align-items: center;
    font-size: ${({ isSmall }) => isSmall ? 1.5 : 2}rem;
    /* font-size: 2rem; */
    line-height: 5rem;
  }
`

/**
 *@ Types
 */
 type Props = {
  isSmall?: boolean
}

export const ConnectionFailedText: React.FC<Props> = ({isSmall=false}) => {
  return (
    <Wrapper isSmall={isSmall}>
      <p>データの取得に失敗しました</p>
    </Wrapper>
  )
}