import React, { useState, useCallback, useMemo, useEffect } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

/**
 * @ Materials
 */
import MainHeader from "./MainHeader"
import SubHeader from "./SubHeader"
import Drawer from './Drawer';
import { SUB_HEADER_MODE } from "./SUB_HEADER_MODE"
import { RESPONSIVE_STYLES } from "../../../AppConst"
import { addQuery } from "../../../store/search"

const Wrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  z-index: 30;
  width: 100%;
  background-color: ${({ isOpen }) => isOpen ? '#222222' : '#fff'};
  transition: 0.5s;
`

/**
 *@ Element & Styles
 */
const PC_Wrapper = styled.div`
  display: block;
  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    display: none;
  }
`
const SP_Wrapper = styled.div`
  display: none;
  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    display: block;
  }
`

type Props = {
}

/**
 *@ ReactComponent
 */
export const Header: React.VFC<Props> = () => {

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAllMenuOpen, setIsAllMenuOpen] = useState<boolean>(false);
  const [isSpOpen, setIsSpOpen] = useState<boolean>(false);
  const [mode, setMode] = useState<SUB_HEADER_MODE | null | undefined>(null);

  const handleMouseOver = useCallback((mode?: SUB_HEADER_MODE, hasSubItems?: boolean) => {
    setIsOpen(hasSubItems ? true : false);
    resetAllMenuOpen();
    setMode(mode)
  }, [isOpen, mode]);

  const handleMouseOut = useCallback(() => {
    setIsOpen(mode === SUB_HEADER_MODE.SEARCH ? true : false)
    setMode(mode === SUB_HEADER_MODE.SEARCH ? mode : null)
    resetAllMenuOpen();
  }, [isOpen, mode]);

  const handleSearchMode = useCallback(() => {
    setIsOpen(mode === SUB_HEADER_MODE.SEARCH ? false : true)
    setMode(mode === SUB_HEADER_MODE.SEARCH ? null : SUB_HEADER_MODE.SEARCH)
    resetAllMenuOpen();
  }, [isOpen, mode]);

  const dispatch = useDispatch()
  const handleTagClick = useCallback((value: string) => {
    dispatch(addQuery(` ${value}`))

    // 寺田メモ 検索フォーム内の文字列に、半角スペース＋タグの文字列を追加
    
  }, [dispatch]);

  const handleHamburgerClick = useCallback((e: React.MouseEvent): void => {
    if(isOpen) {
      setIsAllMenuOpen(false)
      changeStateByHamburgerClick();
      return;
    }

    setIsAllMenuOpen(!isAllMenuOpen);
    changeStateByHamburgerClick();
  }, [isSpOpen, isOpen, isAllMenuOpen])

  const changeStateByHamburgerClick = (): void => {
    setIsOpen(!isOpen)
    setIsSpOpen(!isSpOpen)
    setMode(null)
  }

  const resetAllMenuOpen = () => {
    setIsAllMenuOpen(false)
    setIsSpOpen(false)
  }

  const {
    allSite: { edges },
  } = useStaticQuery(graphql`
    query {
      allSite {
        edges {
          node {
            siteMetadata {
              member {
                pathname
              }
              headerItems {
                main {
                  navItems {
                    titleJa
                    titleEn
                    slug
                  }
                }
                sub {
                  navItems {
                    blog {
                      slug
                      value
                    }
                    service {
                      slug
                      value
                    }
                    recruit {
                      slug
                      value
                    }
                    company {
                      slug
                      value
                    }
                    contact {
                      slug
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const { main, sub } = edges[0].node.siteMetadata.headerItems;

  return (
    <Wrapper isOpen={isOpen}>
      <MainHeader
        isOpen={isOpen}
        isSpOpen={isSpOpen}
        mode={mode as SUB_HEADER_MODE}
        handleHamburgerClick={handleHamburgerClick}
        mainNavItems={main.navItems}
        actions={{
          onMouseOver: handleMouseOver,
        }}
        onClick={handleSearchMode}
      />
      <PC_Wrapper>
        <SubHeader
          onMouseOut={handleMouseOut}
          position={'absolute'}
          mode={mode as SUB_HEADER_MODE}
          isOpen={isOpen}
          isAllMenuOpen={isAllMenuOpen}
          onTagClick={handleTagClick}
          mainNavItems={main.navItems}
          subNavItems={sub.navItems}
        />
      </PC_Wrapper>
      <SP_Wrapper>
        <Drawer isOpen={isSpOpen}/>
      </SP_Wrapper>
    </Wrapper>
  )
}
