import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { IHeaderNavActions } from './IHeaderNavActions';
import { SUB_HEADER_MODE } from './SUB_HEADER_MODE';
import { graphql, useStaticQuery } from "gatsby"

/**
 * Elements & Styles
 */
const Node = styled.li`
  list-style: none;
  height: 100%;
  margin: 0 12px 0;
`

const StyledLink = styled(Link)`
  transition: 0.5s;
  &:hover {
    color: #61B1C1;
  }
`

const Wrapper = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ isOpen }) => isOpen ? `#fff` : `#222` };
  transition: 0.5s;
  &:hover {
    color: #61B1C1;
  }
`
const EN = styled.span`
  font: normal normal normal 1.7rem/2.6rem Righteous;
  letter-spacing: 1.7px;
  white-space: nowrap;
`

const isCurrent = (pathname, slug, edges) => {
  // 現在のURLとNavHeaderItemのURLが同じならカレントとする
  if (pathname == slug) {
    return true;
  }

  // NavHeaderItemのURLをキーにnavItemsを検索し、現在のURLに一致するならばカレントとする
  const urls = edges[0].node.siteMetadata.headerItems.sub.navItems[slug.split("/")[1]];
  if (urls) {
    for (let prop of urls) {
      if ("/" + prop.slug == pathname) {
        return true;
      }
    }
  }

  // ブログ特殊パターン
  if (slug === "/blog") {
    // 検索画面はブログ扱い
    if (pathname === "/search") {
      return true;
    }
    // URLの1つ目がライター名ならば、ブログ扱い
    const member = edges[0].node.siteMetadata.member.find((m) => m.pathname === pathname.split("/")[1]);
    if (member && member.pathname) {
      return true;
    }
  }
  else if (slug === "/team") {
    if (~pathname.indexOf("/member/")) {
      return true;
    }
  }

  return false;
}

/**
 * Types
 */
export type NavItem = {
  titleEn: string
  slug: SUB_HEADER_MODE
  isOpen: boolean
}
type Options = {
  actions?: IHeaderNavActions;
}
type State = {
  hasSubItems: boolean
}
type Props = NavItem & State & Partial<Options>;

/**
 * Components
 */
const NavHeaderItem: React.FC<Props> = props => {

  const {
    allSite: { edges },
  } = useStaticQuery(graphql`
    query {
      allSite {
        edges {
          node {
            siteMetadata {
              member {
                pathname
              }
              headerItems {
                sub {
                  navItems {
                    blog {
                      slug
                    }
                    service {
                      slug
                    }
                    recruit {
                      slug
                    }
                    company {
                      slug
                    }
                    contact {
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  let pathname = typeof window !== `undefined` ? window.location.pathname : '';
  if (pathname.endsWith('/')) {
    pathname = pathname.slice(0, -1);
  }

  const LinkStyle = {
    textDecoration: "none",
    color: isCurrent(pathname, "/" + props.slug, edges) ? "#5db7de" : "#222",
  }

  // TODO: #577 リンククリック後にサブヘッダーを閉じる
  return (
    <Node
      onMouseOver={() => props.actions.onMouseOver(props.slug, props.hasSubItems)}>
      <StyledLink style={LinkStyle} to={"/" + props.slug}>
        <Wrapper isOpen={props.isOpen}>
          <EN>{props.titleEn}</EN>
        </Wrapper>
      </StyledLink>
    </Node>
  )
}

export default NavHeaderItem
