import React from 'react'
import styled from "styled-components"
import { RESPONSIVE_STYLES } from '../../AppConst'

const Node = styled.div`
  text-align: center;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin-top: 23px;
  }
`

const Title = styled.h1<{ size: string, color: string, textAlign: string}>`
  margin: 0;
  font-family: 'Righteous';
  font-weight: normal;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  display: block;
  font-size: ${({ size }) => size === 'medium' ? '4.8rem' : '6.4rem'};
  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign === '' ? 'center' : textAlign};
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: ${({ size }) => size === 'medium' ? '3.2rem' : '3.6rem'};
  }
`

const SubTitle = styled.span<{ size: string, color: string}>`
  display: block;
  letter-spacing: 1.4px;
  font-size: ${({ size }) => size === 'medium' ? '1.4rem' : '1.6rem'};
  font-weight: 600;
  color: ${({ color }) => color};
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 1rem;
    margin-top: 8px;
  }
`
const Icon = styled.img`
  width: 102px;
  margin-bottom: 24px;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 64px;
    margin-bottom: 8px;
  }
`

type Options = {
  size?: 'medium' | 'large';
  color?: string;
  icon?: any;
  textAlign?: string;
}

type Props = {
  title: string;
  subTitle?: string;
} & Options

export const SectionTitle: React.VFC<Props> = (props) => {
  return (
    <Node>
      {
        props.icon && <Icon src={props.icon} alt={props.title} />
      }
      <Title size={props.size} color={props.color} textAlign={props.textAlign}>{props.title}</Title>
      {
        props.subTitle && <SubTitle size={props.size} color={props.color}>{props.subTitle}</SubTitle>
      }
    </Node>
  )
}

SectionTitle.defaultProps = {
  size: 'medium',
  color: '#222',
}
