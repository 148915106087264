import React from 'react'
import styled from "styled-components"
import { Link } from 'gatsby'

import { RESPONSIVE_STYLES } from '../../AppConst'
import { TriangleSwitch } from './TriangleSwitch'

const Circle = styled.span`
  display: inline-block;
  position: relative;
  width: 52px;
  height: 52px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  &:hover > .outside-circle {
    -webkit-transform: scale(1.55);
    -moz-transform: scale(1.55);
    -ms-transform: scale(1.55);
    transform: scale(1.55);
  }
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 32px;
    height: 32px;
  }
`

const LinkCircle = Circle.withComponent(Link)

const TriangleWrapper = styled.div`
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const OutSideCircle = styled.span`
  display: inline-block;
  width: 50px;
  height: 50px;
  margin: 1px;
  border-radius: 50%;
  transition: all 0.3s 0s ease;
  box-sizing: border-box;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 30px;
    height: 30px;
  }
`

  // const TriangleWrapper = styled.div`
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   margin: auto;
  //   zoom: calc(12/20);
  // `

type State = {
  direction: 'top' | 'bottom' | 'right' | 'left';
}

type Options = {
  color?: string;
  type?: string | 'Link';
  to?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

type Props = State & Partial<Options>

export const TriangleInCircleButton:React.VFC<Props> = ({ direction, color, type, to, onClick }) => {
  const rotateVal = () => {
    switch(direction) {
      case 'top':
        return '0';
      case 'bottom':
        return '60';
      case 'right':
        return '-30';
      case 'left':
        return '30';
    }
  }

  const circleStyles = {
    background: color,
    transform: `rotate(${rotateVal()}deg)`
  }
  const outCircleStyles = {
    border: `solid 3px ${color}`
  }
  const arrowColor = color === '#222' ? '#fff' : '#222';

  return (
    <>
      { type === 'Link'
        ?
        <LinkCircle to={to} style={circleStyles}>
          <OutSideCircle style={outCircleStyles} className='outside-circle' />
          <TriangleWrapper>
            <TriangleSwitch color={arrowColor} />
          </TriangleWrapper>
        </LinkCircle>
        :
        <Circle onClick={onClick} style={circleStyles}>
          <OutSideCircle style={outCircleStyles} className='outside-circle' />
          <TriangleWrapper>
            <TriangleSwitch color={arrowColor} />
          </TriangleWrapper>
        </Circle>
      }
    </>
  )
}

TriangleInCircleButton.defaultProps = {
  color: '#fff'
}
