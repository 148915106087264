import React, { useState, useCallback, useMemo } from "react"
import styled from "styled-components"
import { merge } from 'lodash';

import { RESPONSIVE_STYLES, TABLET_STYLES, WRAPPER_STYLES } from "../../../AppConst"
import { IHeaderNavActions } from './IHeaderNavActions';

import { IMainHeaderItem } from "../../../types/IHeaderItem";

/**
 *@ Materials
 */
import Brand from "./Brand"
import NavHeaderNav from "./NavHeaderNav"
import { SearchIcon } from "./SearchIcon"
import { SUB_HEADER_MODE } from "./SUB_HEADER_MODE"
import { HamburgerBtn } from './HamburgerBtn';

import logo from '../../../images/logo_01@2x.svg'
import logoBk from '../../../images/logo_01_bk.svg'
import { SpWrapper } from "../SpWrapper";

/**
 * Elements & Styles
 */
const Node = styled.div<{ isOpen: boolean }>`
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    height: 64px;
    background-color: ${({ isOpen }) => isOpen ? '#222222' : '#fff'}
  }
`

const Wrapper = styled.div<{ isOpen: boolean }>`
  position: relative;
  ${WRAPPER_STYLES}
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 20px;
  transition: 0.5s;
  ${TABLET_STYLES}
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: initial;
    height: 64px;
    padding-bottom: 0;
  }
`

const PC_Wrapper = styled.div<{ isSearchMode: boolean}>`
  right: 0;
  display: flex;
  align-items: center;
  justify-content: ${({ isSearchMode }) => isSearchMode ? 'flex-end' : 'space-between'};
  width: 100%;
  max-width: 800px;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin-top: auto;
    margin-bottom: auto;
  }
  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    display: none
  }
`

const SP_Wrapper = styled.div`
  display: none;
  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    position: relative;
    display: block;
    margin: auto;
  }
`

const BtnWrapper = styled.div`
  margin-left: 24px;
`

/**
 *@ Types
 */
type State = {
  actions: IHeaderNavActions
  isOpen: boolean
  isSpOpen: boolean
  mode: SUB_HEADER_MODE
  mainNavItems: IMainHeaderItem[];
  handleHamburgerClick: (e: React.MouseEvent) => void | null
}

type Options = {
  onClick: (isOpen?: boolean) => void | null,
}

type Props = State & Partial<Options>;

/**
 *@ default
 */
const defaultOptions = (): Options => ({
  onClick: null,
})

/**
 * Component
 */
const MainHeader:React.FC<Props> = (props) => {
  const isSearchMode = props.mode === SUB_HEADER_MODE.SEARCH;
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const options = useMemo(() =>
    merge(defaultOptions(),
    {
      onClick: props.onClick,
    }
  ), [props.onClick]);

  const toggleSearchForm = useCallback(() => {
    setIsSearchOpen(!isSearchOpen);
    options.onClick(!isSearchOpen);
  }, [isSearchOpen, options.onClick]);

  return (
    <Node id="main-header" isOpen={props.isSpOpen}>
      <SpWrapper>
        <Wrapper isOpen={props.isSpOpen}>
          <Brand
            slug={"/"}
            img={props.isOpen ? logoBk : logo}
            isOpen={props.isOpen}
          />
          <PC_Wrapper isSearchMode={isSearchMode}>
            {!isSearchMode &&
              (
                <>
                  <NavHeaderNav
                    navItems={props.mainNavItems}
                    actions={props.actions}
                    isOpen={props.isOpen}
                  />
                  <BtnWrapper>
                    <SearchIcon onClick={toggleSearchForm} />
                  </BtnWrapper>
                </>
              )
            }
            <BtnWrapper>
              <HamburgerBtn isOpen={props.isOpen} onClick={props.handleHamburgerClick}/>
            </BtnWrapper>
          </PC_Wrapper>
          <SP_Wrapper>
            <HamburgerBtn isOpen={props.isSpOpen} onClick={props.handleHamburgerClick}/>
          </SP_Wrapper>
        </Wrapper>
      </SpWrapper>
    </Node>
  )
}

export default MainHeader
