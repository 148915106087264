import styled from 'styled-components';
import { RESPONSIVE_STYLES } from "../../AppConst"

export const SpWrapper = styled.div`
  box-sizing: border-box;

  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`