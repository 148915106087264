import React from 'react'
import { Link } from "gatsby"
import styled from 'styled-components'
import { ISubHeaderItem } from '../../../types/IHeaderItem';

const Node = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 24px 0 48px;
`
const Container = styled.div`
  width: 100%;
`

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  margin: 0;
  padding: 8px 0;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  text-decoration: none;
  text-align: left;
  &:hover {
    color: #61B1C1;
    text-decoration: underline;
  }
`

type State = {
  subNavItems: ISubHeaderItem[];
}

type Props = State;

export const SubHeaderItemAllList: React.FC<Props> = ({ subNavItems }) => {
  return (
    <Node>
      {subNavItems.map((item, index) => {
        return (
          <Container key={index}>
            <StyledLink to={`/${item.slug}`}>
              {item.value}
            </StyledLink>
          </Container>
        )})
      }
    </Node>
  )
}
