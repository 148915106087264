import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from 'gatsby'

import NavHeaderItem from "./NavHeaderItem"
import { IHeaderNavActions } from './IHeaderNavActions';

import { IMainHeaderItem } from "../../../types/IHeaderItem";
import { SUB_HEADER_MODE } from "./SUB_HEADER_MODE";

/**
 * Elements & Styles
 */
const Nav = styled.nav`
  display: inline-block;
  margin: 0;
  width: 100%;
`
const NavItemList = styled.ul`
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  & > li {
    :last-child {
      margin-right: 0;
    }
  }
`

/**
 * Types
 */
type Options = {
  flex: string;
}
type State = {
  navItems: IMainHeaderItem[];
  actions?: IHeaderNavActions;
  isOpen: boolean;
}
type Props = State & Partial<Options>;

/**
 * Component
 */
const NavHeaderNav: React.FC<Props> = props => {
  const {
    allSite: { edges },
  } = useStaticQuery(graphql`
    query {
      allSite {
        edges {
          node {
            siteMetadata {
              headerItems {
                sub {
                  navItems {
                    blog {
                      value
                      slug
                    }
                    service {
                      value
                      slug
                    }
                    recruit {
                      value
                      slug
                    }
                    company {
                      value
                      slug
                    }
                    contact {
                      value
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const hasSubItems = (slug: string): boolean => {
    const subItems = edges[0].node.siteMetadata.headerItems.sub.navItems[slug];
    const isAllEmpty = subItems.every(({ value }) => !value)
    return !isAllEmpty
  }

  return (
    <Nav>
      <NavItemList>
        {props.navItems.map((item, index) => {
          const { slug, titleEn } = item;
          
          return (
            <NavHeaderItem 
              key={`${slug}${index}`} 
              titleEn={titleEn}
              slug={slug as SUB_HEADER_MODE}
              isOpen={props.isOpen}
              actions={props.actions}
              hasSubItems={hasSubItems(slug)}
            />
          )
        })}
      </NavItemList>
    </Nav>
  )
}

export default NavHeaderNav
