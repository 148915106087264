import React from 'react'
import styled from "styled-components"

const Triangle = styled.span<{ color: string | null | undefined }>`
  display: inline-block;
  justify-content: center;
  align-items: center;

  & svg {
    transform: rotate(90deg);
    fill: ${({ color }) => color ? color : "#222222"};
  }
`

const Svg = styled.svg`
  transform: rotate(90deg);
  fill: ${({ color }) => color ? color : "#222222"};
`

type Props = { color?: string };

export const TriangleSwitch : React.VFC<Props> = props => {
  return (
    <Svg color={props.color} width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.38128 4.85762L1.84816 0.167584C1.66041 0.0558344 1.44899 -0.00195214 1.23444 4.99252e-05C1.01988 0.00205199 0.809455 0.0636924 0.623534 0.178929C0.437614 0.294165 0.282522 0.459094 0.173282 0.657786C0.0640421 0.856479 0.00436683 1.08221 3.52286e-05 1.31302L3.48195e-05 10.6716C-0.00161982 10.9049 0.0543103 11.1345 0.162124 11.337C0.269937 11.5394 0.425765 11.7075 0.613695 11.824C0.801624 11.9405 1.01492 12.0012 1.2318 12C1.44868 11.9988 1.66137 11.9356 1.84816 11.817L9.38128 7.14862C9.5692 7.03341 9.72544 6.86673 9.83411 6.66554C9.94278 6.46434 10 6.23575 10 6.00305C10 5.77035 9.94278 5.54189 9.83411 5.3407C9.72544 5.13951 9.5692 4.97282 9.38128 4.85762Z" />
    </Svg>
  )
}
