import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"

/**
 * Material
 */
import { RESPONSIVE_STYLES, WRAPPER_STYLES } from '../../../AppConst'
import { SnsIcons } from './SnsIcons'
import { FooterLinkItems } from "./FooterLinkItems"

import logo from "../../../images/nextribe_logo_wh.svg";

/**
 * Element & Styles
 */
const Node = styled.div`
  width: 100%;
  min-width: ${RESPONSIVE_STYLES.MAX_WIDTH}px;
  padding: 96px 0;
  background-color: #222222;
  bottom: 0;
  box-sizing: border-box;
  @media(max-width: ${RESPONSIVE_STYLES.MAX_WIDTH}px) {
    padding: 96px 20px;
  }
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    position: relative;
    width: 100%;
    min-width: auto;
    height: auto;
    padding: 45px 20px 20px;
  }
`

const Wrapper = styled.div`
  ${WRAPPER_STYLES}
`

const PC_Wrapper = styled.div`
  display: block;
  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    display: none;
  }
`

const MenuContainer = styled.div`
  padding-bottom: 48px;
  border-bottom: 1px solid #FFFFFF;
`

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    flex-direction: column;
    margin-bottom: 24px;
  }
`

const LogoImageWrapper = styled.div`
  width: 280px;
  margin-bottom: 40px;
  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    width: 180px;
    margin-bottom: 24px;
  }
`

const Img = styled.img``

const Address = styled.div`
  margin-left: 24px;
  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    margin-left: 0px;
  }
  & .name {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    margin-bottom: 8px;
    @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
      font-size: 1.6rem;
      letter-spacing: 1.6px;
    }
  }
  & .address {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.05em;
    line-height: 21px;
    color: #FFFFFF;
    @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
      font-size: 1rem;
      line-height: 1rem;
      letter-spacing: 1px;
    }
  }
`

const StyledLink = styled(Link)`
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  text-decoration: none;
  margin-top: 56px;
  margin-right: 48px;
  &:hover {
    color: #61B1C1;
    text-decoration-line: underline;
    ::after {
      background-color: #61B1C1;
    }
  }
`

const OtherItemsWrapper = styled.div`
  display: flex;
  margin-bottom: 56px;
`

/**
 * Component
 */
const Footer:React.VFC = () => {
  const {
    file,
    allSite: { edges },
  } = useStaticQuery(graphql`
    query {
      allSite {
        edges {
          node {
            siteMetadata {
              navHeaderItems {
                titleJa
                titleEn
                slug
              }
              blogItems {
                item
                slug
              }
              serviceItems {
                item
                slug
              }
              recruitItems {
                item
                slug
              }
              companyItems {
                item
                slug
              }
              contactItems {
                item
                slug
              }
              otherItems {
                item
                slug
                title
              }
            }
          }
        }
      }
    }
  `)

  // TODO: #577 フッターのレイアウト変更
  return (
    <Node>
      <Wrapper>
        <PC_Wrapper>
          <MenuContainer>
            <FooterLinkItems data={edges[0].node.siteMetadata}/>
          </MenuContainer>
          <OtherItemsWrapper>
            {edges[0].node.siteMetadata.otherItems.map((elem, index) => {
              if (elem.title === 'contact') return;

              return (
                <StyledLink key={index} to={`/${elem.slug}`}>{elem.item}</StyledLink>
              )
            })}
          </OtherItemsWrapper>
        </PC_Wrapper>
        <LogoWrapper>
          <div className='sp-wrapper'>
            <LogoImageWrapper>
              <Img src={logo} alt='ネクストライブ株式会社ロゴ' />
            </LogoImageWrapper>
            {
              // TODO: 運用後有効に
              // <SnsIcons />
            }
          </div>
          <Address>
            <div className='name'>ネクストライブ株式会社</div>
            <div className='address'>〒553-0003 大阪市福島区福島1-4-4 セントラル70 3F</div>
          </Address>
        </LogoWrapper>
      </Wrapper>
    </Node>
  )
}

export default Footer