import React from "react"
import styled from "styled-components"
import loadingImg from "../../images/loading@2x.png"

type Props = {
  id?: string
}

const Wrapper = styled.div`
  width: 100%;
  height: 200px;
  z-index: 15;
  transform: translateZ(1px);

  .container {
    background: #fff;
  }
  .loading {
    position: relative;
    background-image: url(${loadingImg});
    background-size: 60px 60px;
    background-repeat: no-repeat;
    width: 60px;
    height: 60px;
    top: 50%;
    left: 50%;
    margin-left: -30px;
    margin-top: -30px;
    z-index: 16;
    animation: loading 1s linear infinite;
    transform: translateZ(1px);
    @keyframes loading {
      0% { transform: rotateY(-0deg); }
      100% { transform: rotateY(360deg); }
    }
  }
`

export const LoadingAPI: React.FC<Props> = ({id="loadingAPI"})  => {
  return (
    <Wrapper id={id}>
      <div className="container"></div>
      <div className="loading"></div>
    </Wrapper>
  )
}

