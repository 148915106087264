import { useStaticQuery, graphql } from "gatsby"
import React, { memo, useMemo, useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import HeaderAccordion from "./HeaderAccordion"
import { Options } from "./HeaderForm"

/**
 * @Types
 */
type DrawerItem = { title: string; items: { slug: string; value: string }[] }

type DrawerType = {
  blog: DrawerItem
  company: DrawerItem
  contact: DrawerItem
  recruit: DrawerItem
  team: DrawerItem
}

type OtherMenuType = { item: string; slug: string; title: string }[]

type ContainerProps = {
  isOpen: boolean
} & Partial<Options>

type Props = {
  className?: string
  isDrawerOpen?: boolean
  drawerItems: DrawerItem[]
  otherMenuItems: OtherMenuType
} & ContainerProps

const Drawer: React.FC<Props> = memo(
  ({ className, drawerItems, otherMenuItems, isOpen }) => {

    return (
      <div className={className}>
        <div className="drawer-wrapper">
          {drawerItems.map(({ title, items }, i) => {
            return (
              <div key={i} className="drawer-list">
                <HeaderAccordion title={title} items={items} isOpen={isOpen} />
              </div>
            )
          })}
        </div>
        <div className="drawer__sub__item__wrapper">
          {otherMenuItems.map(({ item, slug }, idx) => {
            if (slug === "contact") return;

            return (
              <div key={idx} className="drawer__sub__item">
                <div className="drawer__sub__item__inner">
                  <Link to={`/${slug}`}>{item}</Link>
                </div>
              </div>
            )}
          )}
        </div>
      </div>
    )
  }
)

const StyledDrawer = styled(Drawer)`
  width: 100%;
  padding-bottom: ${props => (props.isOpen ? `64px` : `0`)};
  background-color: #222222;
  transition: 0.5s;
  height: ${props => (props.isOpen ? `calc(100vh - 64px)` : 0)};
  overflow: scroll;
  box-sizing: border-box;
  & .drawer-wrapper {
    opacity: ${props => (props.isDrawerOpen ? `1` : `0`)};
    transition: 0.5s;
  }
  & .drawer-list {
    background-color: #222222;
    & .drawer-list-contact-wrapper {
      margin: 30px 20px 0;
      background-color: #222;
      padding: 25px;
    }
    & .drawer-list-contact-link {
      text-decoration: none;
      color: #fff;
      font: normal normal normal 2.4rem/2.6rem Righteous;
      letter-spacing: 2.4px;
    }
    & .contact-message {
      font: normal normal normal 10px/26px Hiragino Sans;
      opacity: 0.5;
      letter-spacing: 1px;
      margin-left: 16px;
    }
    & .drawer-list-contact-inner {
      display: flex;
    }
  }

  & .drawer__sub__item {
    display: ${props => props.isOpen ? `block` : `none`};
    width: 100%;
    padding: 0 20px;
    background-color: #222222;
    box-sizing: border-box;
  }
  & .drawer__sub__item__wrapper {
    padding: 24px 0;
    transition: 0.5s;
    opacity: ${props => props.isDrawerOpen ? 1 : 0};
  }
  & .drawer__sub__item__inner {
    width: 100%;
    display: flex;
    padding: 8px 0;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    & a {
      text-decoration: none;
      color: #FFFFFF;
      font: normal normal 700 14px/21px Hiragino Sans;
      letter-spacing: 0.05em;
      &:hover {
        color: #61b1c1;
        text-decoration: underline;
      }
    }
  }
`
const DrawerContainer: React.FC<ContainerProps> = React.memo(props => {
  const [isDrawerOpen, update] = useState(false)

  useEffect(() => {
    let id: NodeJS.Timeout
    
    if (props.isOpen) {
      id = setTimeout(() => update(true), 200)
    } else {
      update(false)
    }
    return () => clearTimeout(id)
  }, [props.isOpen])

  const {
    allSite: { edges },
  } = useStaticQuery(graphql`
    query {
      allSite {
        edges {
          node {
            siteMetadata {
              drawer {
                navItems {
                  blog {
                    title
                    items {
                      slug
                      value
                    }
                  }
                  service {
                    title
                    items {
                      slug
                      value
                    }
                  }
                  recruit {
                    title
                    items {
                      slug
                      value
                    }
                  }
                  company {
                    title
                    items {
                      slug
                      value
                    }
                  }
                  contact {
                    title
                    items {
                      slug
                      value
                    }
                  }
                }
              }
              otherItems {
                item
                slug
                title
              }
            }
          }
        }
      }
    }
  `)

  const drawerItems = useMemo(() => {
    const items: DrawerType = edges.map(
      ({ node }) => node.siteMetadata.drawer.navItems
    )[0]

    return Object.keys(items).map(key => items[key])
  }, [edges])

  const otherItems = useMemo(() => {
    const items: OtherMenuType = edges.map(
      ({ node }) => node.siteMetadata.otherItems
    )[0]

    return items;
  }, [edges])

  return (
    <StyledDrawer
      isOpen={props.isOpen}
      isDrawerOpen={isDrawerOpen}
      drawerItems={drawerItems}
      otherMenuItems={otherItems}
    />
  )
})

export default DrawerContainer
