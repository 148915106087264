import React from "react"
import Helmet from "react-helmet"

export default props => {
  const url = typeof window !== `undefined` ? window.location.href : '';
  return (
    <Helmet
      htmlAttributes={{
        lang: "ja",
      }}
      title={props.title ? props.title : "ネクストライブ株式会社"}
      link={[
        {
          rel: "canonical",
          href: url
        },
      ]}
      meta={[
        {
          name: "description",
          content: props.description || "",
        },
        {
          property: "og:locale",
          content: "ja_JP",
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:title",
          content: props.title
        },
        {
          property: "og:description",
          content: props.description
        },
        {
          property: "og:url",
          content: url
        },
        {
          property: "og:site_name",
          content: "ネクストライブ株式会社／NEXTRIBE｜大阪のHR Techベンチャー"
        },
        {
          property: "og:image",
          content: props.img
        },
        {
          name: "twitter:card",
          content: "summary"
        },
        {
          name: "twitter:site",
          content: "@nextribe"
        },
        {
          name: "twitter:creator",
          content: "@nextribe"
        },
        {
          name: "facebook-domain-verification",
          content: "on5rr4x0zepmrjm6qck0jkii7lvbmk"
        },
      ]}
    >
      <script>{`
          {
            (function(d) {
              var config = {
                kitId: 'cnj4jic',
                scriptTimeout: 3000,
                async: true
              },
              h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
            })(document);
          }
        `}</script>
    </Helmet>
  )
}
