import React from "react"
import styled from "styled-components"
import { ISubHeaderItem } from "../../../types/IHeaderItem"
import { IHeaderNavActions } from "./IHeaderNavActions"
import { RESPONSIVE_STYLES } from "../../../AppConst"

/**
 * Element & style
 */
const Text = styled.span`
  display: inline-block;
  position: relative;
  letter-spacing: 1.4px;
  white-space: nowrap;
  margin: 0 20px;
  font-size: 1.4rem;
  font-weight: 600;
  @media(max-width: ${RESPONSIVE_STYLES.MAX_WIDTH}px) {
    margin: 0 16px;
  }
`

/**
 * Types
 */
type Options = {
  marginRight: number
  actions: IHeaderNavActions
}
type State = {} & ISubHeaderItem
type Props = State & Partial<Options>

/**
 * ReactComponent
 */
const SubHeaderItem: React.FC<Props> = props => {

  let pathname = typeof window !== `undefined` ? window.location.pathname : '';
  if (pathname.endsWith('/')) {
    pathname = pathname.slice(0, -1);
  }

  return (
    <Text className="subheader__nav__item">
      {props.value}
    </Text>
  )
}

export default SubHeaderItem
