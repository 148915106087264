import { useState, useMemo, useCallback } from "react"

export const useAccordion = () => {
  const [state, update] = useState({
    isOpen: false,
  })

  const btnStyle = useMemo(
    () => ({
      width: `100%`,
      cursor: `pointer` as `pointer`,
      outline: `none` as `none`,
      boxSizing: `border-box` as `border-box`,
      transition: `0.5s`,
    }),
    [state.isOpen]
  )
  const arrowStyle = useMemo(
    () => ({
      transition: `0.5s`,
      transform: state.isOpen ? `` : `rotate(180deg)`,
    }),
    [state.isOpen]
  )
  const ulStyle = useMemo(
    () => ({
      listStyle: `none` as `none`,
      margin: state.isOpen ? `16px 0 0` : 0,
      padding: `0 20px`,
      boxSizing: `border-box`,
    }),
    [state.isOpen]
  )
  const liStyle = useMemo(
    () => ({
      font: `normal normal 700 14px/21px Hiragino Sans`,
      padding: state.isOpen ? `0` : 0,
      opacity: state.isOpen ? 1 : 0,
      display: state.isOpen ? `block` : `none`,
      transition: `0.5s`,
    }),
    [state.isOpen]
  )
  const textStyle = useMemo(
    () => ({
      letterSpacing: `0.95`,
      display: state.isOpen ? `flex` : `none`,
    }),
    [state.isOpen]
  )

  const toggle = useCallback(() => {
    update(_state => ({
      ..._state,
      isOpen: !_state.isOpen,
    }))
  }, [])
  return {
    btnStyle,
    arrowStyle,
    ulStyle,
    liStyle,
    textStyle,
    toggle,
  }
}
