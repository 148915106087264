import React from "react"
import styled from "styled-components"

/**
 * @ Element & Styles
 */
const Wrapper = styled.div`
  margin-bottom: 24px;
  border-bottom: 1px solid #FFFFFF;
`

const TitleWrapper = styled.div`
  margin-bottom: 16px;
  font-family: 'Righteous';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  color: #61B1C1;
`

/**
 * @ Props
 */
export type CategoryItem = {
  titleEn: string
  slug: string
}

type Props = CategoryItem

/**
 * @ Component
 */
const FooterCategoryItem: React.FC<Props> = props => {
  return (
    <Wrapper>
      <TitleWrapper>
        {props.titleEn}
      </TitleWrapper>
    </Wrapper>
  )
}

export default FooterCategoryItem
