import React, { ChangeEvent, useMemo } from "react"
import styled from "styled-components"
import { merge } from "lodash"

/**
 * Element & Style
 */
const Node = styled.div`
  width: 100%;
  position: relative;
  & .fas {
    position: absolute;
    top: 6px;
    left: 6px;
    color: #FFFFFF;
    font-size: 24px;
  }
`

const Form = styled.input`
  outline: none;
  box-sizing: border-box;
  line-height: 2.6rem;
  padding-left: 40px;
  color: #FFFFFF;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #FFFFFF;
    font-size: 24px;
    line-height: 2.6rem;
    opacity: 0.8;
  }
  :-ms-input-placeholder {
    color: #FFFFFF;
    font-size: 24px;
    line-height: 2.6rem;
    opacity: 0.8;
    display: block !important;
  },
`

/**
 * Type
 */
type Options = {
  width: number
  maxWidth?: number
  height: number
  backGroundColor: string
  border: string
  borderBottom: string
  fontSize: string
  onChanges: ((e: ChangeEvent<HTMLInputElement>) => void) | null
  onKeyPress: ((e: React.KeyboardEvent<HTMLInputElement>) => void) | null
}
type State = {
  value?: string
  placeHolder?: string
}
type Props = State & Partial<Options>

/**
 * default
 */
const defaultOptions = (): Options => ({
  width: 100,
  height: 40,
  fontSize: '24px',
  backGroundColor: "#efefef",
  border: 'none',
  borderBottom: 'none',
  onChanges: null,
  onKeyPress: null
})

const Container: React.FC<Props> = props => {
  const options = useMemo(
    () =>
      merge(defaultOptions(), {
        width: props.width,
        maxWidth: props.maxWidth,
        height: props.height,
        backGroundColor: props.backGroundColor,
        border: props.border,
        borderBottom: props.borderBottom,
        fontSize: props.fontSize,
        onChanges: props.onChanges,
        onKeyPress: props.onKeyPress,
      }),
    [props.width, props.height, props.onChanges, props.onKeyPress]
  )
  const fromStyle = useMemo(
    () => ({
      width: `${options.width}%`,
      maxWidth: `${options.maxWidth}px`,
      height: `${options.height}px`,
      border: options.border,
      borderBottom: options.borderBottom,
      fontSize: options.fontSize,
      backgroundColor: options.backGroundColor
    }),
    [options.width, options.height, options.border, options.fontSize, options.maxWidth, options.backGroundColor]
  )
  return (
    <Node>
      <i className="fas fa-search"></i>
      <Form
        style={fromStyle}
        placeholder={props.placeHolder}
        value={props.value}
        onChange={options.onChanges}
        onKeyPress={options.onKeyPress}
      />
    </Node>
  )
}

export { Container as SearchForm }
