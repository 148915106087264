import React from 'react'
import styled from 'styled-components'
import TagList from '../TagList'
import HeaderForm from './HeaderForm'

/**
 * @ Elements & Styles
 */
const Node = styled.div`
  width: 100%;
  margin-top: 24px;
`

const TagListWrapper = styled.div`
  margin-block: 48px 10px;
`

/**
 * @ Types
 */
type State = {
  values: string[]
}

type Options = {
  onClick: (value: string) => void | null;
}

type Props = State & Partial<Options>

export const SubHeaderSearchForm: React.FC<Props> = ({ values, onClick }) => {
  return (
    <Node>
      <HeaderForm />
      <TagListWrapper>
        <TagList values={values} onClick={onClick} />
      </TagListWrapper>
    </Node>
  )
}
