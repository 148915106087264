import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { merge } from 'lodash';

/**
 *@ Elements & styles
 */
const Node = styled.span<{isLong: boolean}>`
  display: inline-block;
  padding: 5.5px 8px;
  box-sizing: border-box;
  border-radius: 4px;

  font-size: ${({ isLong }) => isLong ? '1.2rem' : '1.4rem'};
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.7px;

  background-color: #ffffff;
  cursor: pointer;
  transition: .3s;

  &:hover {
    color: #fff;
    background-color: #61B1C1;
    border-color: #61B1C1;
  }
`;

/**
 *@ Types
 */
type Options = {
  onClick: (value?: string) => void | null;
  slug: string;
}

type State = {
  value: string;
}

type Props = State & Partial<Options>;

/**
 *@ default
 */
const defaultOptions = (): Options => ({
  onClick: null,
  slug: ''
})

/**
 *@ ReactComponent
 */
const Tag: React.FC<Props> = (props) => {
  const options = useMemo(() => merge(defaultOptions(), {
    slug: '',
    onClick: props.onClick
  }), [
    props.slug,
    props.onClick
  ]);
  const content = useMemo(() => {
    return options.slug
      ? <Link to={"/" + options.slug} style={{textDecoration: 'none', color: '#000000'}}>#{props.value}</Link>
      : `#${props.value}`;
  }, [
    options.slug
  ])
  const onClick = useCallback(() => {
    if(!options.onClick) return;
    options.onClick(props.value);
  }, [options.onClick])
  return (
    <Node onClick={onClick} isLong={props.value.length > 20 } >
      {content}
    </Node>
  );
}

export default Tag;
