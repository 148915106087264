import React, { useMemo, useState, useEffect, useRef, useCallback } from "react"
import { useSelector } from "react-redux";
import styled from "styled-components"
import { merge } from 'lodash';

import { WRAPPER_STYLES, SearchTags } from "../../../AppConst"
import { IMainHeaderItem, ISubHeaderItem } from "../../../types/IHeaderItem"
import { SUB_HEADER_MODE } from "./SUB_HEADER_MODE"

/**
 *@ Materials
 */
import SubHeaderItemList from "./SubHeaderItemList"
import { SubHeaderTitle } from "./SubHeaderTitle";
import { SubHeaderAllItems } from "./SubHeaderItemAll";
import { SubHeaderSearchForm } from "./SubHeaderSearchForm";

/**
 * @ Element & style
 */
const Node = styled.div<{ isAllMenu: boolean }>`
  width: 100%;
  background-color: #222222;
  z-index: 10;
  box-sizing: border-box;
  position: fixed;
  height: fit-content;
  max-height: 100vh;
  ${({ isAllMenu }) => isAllMenu ? "overflow-y: scroll;" : ''}
`
const Wrapper = styled.div`
  ${WRAPPER_STYLES}
  text-align: center;
`

/**
 * @ Types
 */
type ISubHeaderItems = {
  [key: string]: ISubHeaderItem[];
}

type State = {
  mode?: SUB_HEADER_MODE;
  mainNavItems: IMainHeaderItem[];
  subNavItems: ISubHeaderItems;
  isOpen: boolean;
  isAllMenuOpen: boolean;
}

type Options = {
  position: 'relative' | 'absolute';
  transition: string;
  onMouseOut: (() => void) | null;
  onTagClick: ((value: string) => void) | null;
}

type Props = State & Partial<Options>

/**
 * @ default
 */
const defaultOptions = ():Options => ({
  position: 'relative' as 'relative',
  transition: '0.5s',
  onMouseOut: null,
  onTagClick: null
})

/**
 * @ ReactComponent
 */
const SubHeader: React.FC<Props> = props => {
  const nodeRef = useRef<HTMLDivElement>();
  const options = useMemo(() => merge(defaultOptions(), {
    position: props.position,
    transition: props.transition,
    onMouseOut: props.onMouseOut,
    onTagClick: props.onTagClick
  }), [
    props.position,
    props.transition,
    props.onMouseOut,
    props.onTagClick
  ]);
  const tags = SearchTags;
  const [subNavItems, setSubNavItems] = useState<ISubHeaderItem[]>([]);

  const items = useMemo((): ISubHeaderItem[] => {
    // ヘッダのナビゲーションの項目の増減はここを実装
    switch (props.mode) {
      case SUB_HEADER_MODE.BLOG:
        return props.subNavItems.blog
      case SUB_HEADER_MODE.SERVICE:
        return props.subNavItems.service
      case SUB_HEADER_MODE.COMPANY:
        return props.subNavItems.company
      case SUB_HEADER_MODE.CONTACT:
        return props.subNavItems.contact
      case SUB_HEADER_MODE.RECRUIT:
        return props.subNavItems.recruit
      default:
        return []
    }
  }, [props.mode]);

  const nodeStyles = useMemo(() => ({
    opacity: props.isOpen ? '1' : '0',
    position: options.position,
    transition: options.transition,
    padding: props.isOpen ? '76px 184px 96px' : '0px 184px'
  }), [props.isOpen, options.position, options.transition]);

  useEffect(() => {
    setSubNavItems(items)
  }, [items]);

  const handleMouseOver = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (nodeRef.current?.contains(e.relatedTarget as Node)) {
      return;
    } else {
      if (props.mode === SUB_HEADER_MODE.SEARCH) {
        return;
      }
      
      if (!props.onMouseOut) return;
      
      props.onMouseOut();
    }
  }, [props.onMouseOut]);
  
  const content = useMemo(() => {
    const isSearchMode = props.mode === SUB_HEADER_MODE.SEARCH;

    if (isSearchMode) {
      return <SubHeaderSearchForm values={tags} onClick={options.onTagClick}/>
    }

    if (props.isAllMenuOpen) {
      return <SubHeaderAllItems mainNavItems={props.mainNavItems} subNavItems={props.subNavItems} />
    }

    return <SubHeaderItemList items={subNavItems}/>

  }, [props.mode, props.isAllMenuOpen, subNavItems, tags]);

  return (
    <Node
      ref={nodeRef}
      style={nodeStyles}
      onMouseOut={handleMouseOver}
      isAllMenu={props.isAllMenuOpen}
    >
      <Wrapper>
        <SubHeaderTitle mode={props.mode} mainNavItems={props.mainNavItems} />
        {content}
      </Wrapper>
    </Node>
  );
}

export default SubHeader
