import React, { useCallback } from "react"
import styled from "styled-components"

/**
 * Material
 */
import { SearchForm } from "./Form"
import { useDispatch, useSelector } from "react-redux"
import {
  selectQuery,
  setQuery,
} from "../../../store/search"
import { navigate } from "gatsby"

/**
 * Element & Styles
 */
const Node = styled.div`
  display: flex;
  align-items: center;
`

/**
 * Types
 */
export type Options = {
  onCloseBtnClick: (() => void) | null
}
type Props = Partial<Options>

/**
 * ReactComponent
 */
const HeaderForm: React.FC<Props> = props => {
  const query = useSelector(selectQuery)
  const dispatch = useDispatch()

  const handleFromChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setQuery(e.target.value))
    },
    [dispatch]
  )
  const handleSearchOnPressEnter = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if(e.key === 'Enter') {
      e.preventDefault()
      // TODO queryがnullの時は全件取れてくるけどOK？？
      const searchParam = query?.replace(/^\s+/, '').replace(/\s+/g, '+');
      navigate(`/search?query=${searchParam}`);
    }
  }, [dispatch, query])

  const formStyles = {
    backGroundColor: 'transparent',
    borderBottom: '1px solid #ffffff',
  }

  return (
    <Node>
      <SearchForm
        {...formStyles}
        onChanges={handleFromChange}
        onKeyPress={handleSearchOnPressEnter}
        value={query}
        placeHolder="検索"
      />
    </Node>
  )
}

export default HeaderForm
