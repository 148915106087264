import React from "react"
import styled from "styled-components"

/**
 *@ Materials
 */
import Tag from "./Tag"

/**
 *@ Elements & Styles
 */
const Node = styled.ul`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;

  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`
const Wrapper = styled.li`
  list-style: none;
`

/**
 *@ Types
 */
type Options = {
  onClick: (value: string) => void | null;
}

type State = {
  values: string[]
}

type Props = State & Partial<Options>

/**
 *@ ReactComponent
 */
const TagList: React.FC<Props> = props => {
  return (
    <Node>
      {props.values.map((value, index) => {
        return (
          <Wrapper key={index}>
            <Tag value={value} onClick={props.onClick} />
          </Wrapper>
        )
      })}
    </Node>
  )
}

export default TagList
