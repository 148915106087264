import React from "react"
import styled from "styled-components"
import loadingImg from "../../images/loading@2x.png"

const Wrapper = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  transform: translateZ(1px);
  .container {
    background: #fff;
    width: 100vw;
    height: 100vh;
  }
  .loading {
    background-image: url(${loadingImg});
    background-size: 60px 60px;
    background-repeat: no-repeat;
    width: 60px;
    height: 60px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -30px;
    margin-top: -30px;
    z-index: 21;
    animation: loading 1s linear infinite;
    transform: translateZ(1px);
    @keyframes loading {
      0% { transform: rotateY(-0deg); }
      100% { transform: rotateY(360deg); }
    }
  }
`

export const Loading: React.FC = () => {
  return (
    <Wrapper id="loading">
      <div className="container"></div>
      <div className="loading"></div>
    </Wrapper>
  )
}

