import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby";
import Slider from "react-slick";
import { TriangleInCircleButton } from "../common/TriangleInCircleButton";
import { RESPONSIVE_STYLES, OWNEDMEDIA_WORDPRESS_URL } from "../../AppConst"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ArticleSliderCard from "./ArticleSliderCard";
import { WPFrontMatter } from "../../types/WPFrontMatter";
import { LoadingAPI } from "../common/LoadingAPI";
import { ConnectionFailedText } from "../common/ConnectionFailedText";

const fetchBlogTopSliderPosts = async (setPosts, setIsLoading, setHasError) => {
  setIsLoading(true);

  fetch(`${OWNEDMEDIA_WORDPRESS_URL}/wp-json/wp/v2/pickup_kinds?slug=blog-top`)
  .then(response => {return response.json()})
  .then(pickupKinds => {
    fetch(`${OWNEDMEDIA_WORDPRESS_URL}/wp-json/wp/v2/posts?_embed&pickup_kinds=${pickupKinds[0].id}&per_page=5`)
    .then(response => {return response.json()})
    .then(postsData => {
      const posts: WPFrontMatter[] = [];

      postsData.map((data, i) => {
        const postData: WPFrontMatter = {
          title: data.title.rendered,
          description: data.excerpt.rendered,
          date: data.date,
          category: data._embedded["wp:term"][0][0].slug,
          author: data._embedded.author[0].name,
          thumbnail: data._embedded["wp:featuredmedia"][0].source_url,
          slug: `${data._embedded.author[0].slug}/${data.slug}`
        }

        posts.push(postData);
      })

      setPosts(posts);
      setIsLoading(false);
    })
    .catch((error) => {
      setHasError(true);
    });
  })
  .catch((error) => {
    setHasError(true);
  });
};

const settings = {
  autoplay: true,
  centerMode: true,
  dots: true,
  speed: 500,
  variableWidth: true,
}

const Node = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const PickUpArticles = ({posts, members}) => {
  const sliderRef = useRef();

  // スライド部分に表示する記事数
  const DisplayCount = posts.length;
  // スライドに表示する要素の横幅（padding含む）
  const DisplayItemWidth = 1006;
  // スライドに表示する要素のレスポンシブの横幅（padding含む）
  const DisplayItemResponsiveWidth = 312;
  const Wrapper = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: .1;
    }
    .slick-track {
      width: ${DisplayItemWidth * DisplayCount * 3}px !important;
      display: flex;
      justify-content: center;
      height: 100%;
      margin-bottom: 80px;
      @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
        width: ${DisplayItemResponsiveWidth * DisplayCount * 3}px !important;
        margin-bottom: 48px;
        min-height: auto;
      }
    }
    .slick-slide {
      width: ${DisplayItemWidth}px;
      padding: 0 104px !important;
      transition: .3s;
      box-sizing: border-box;
      @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
        width: ${DisplayItemResponsiveWidth}px;
        padding: 0 20px !important;
      }
    }
    .slick-slide.slick-active,
    .slick-slide[data-active=true] {
      .article {
        @media screen and (min-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
          max-width: 600px;
        }
      }
    }
    .slick-dots {
      li {
        width: 40px;
        height: 6px;
        margin: 0 8px;
      }
      button {
        &::before {
          opacity: 1;
          background: #E9E9E9;
          content: '';
          width: 40px;
          height: 6px;
        }
      }
      .slick-active {
        button {
          &::before {
            background: #222222;
          }
        }
      }
    }
  `

  const SliderWrapper = styled.div`
    padding-top: 80px;
    padding-bottom: 95px;
    @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      padding-top: 48px;
      padding-bottom: 80px;
    }
  `

  // スライドの矢印ボタンについて、画面の中心からの位置を返す
  const getArrowButtonDistanceForHalf = (prevOrNext: string) => {
    // スライドに表示している要素の半径（余白含まない）
    const itemHalf = 399;
    // スライドに表示している要素間の余白
    const distanceBetweenItems = 104;
    // ボタンの半径
    const buttonHalf = 26;

    if(prevOrNext === 'prev')
      return itemHalf + distanceBetweenItems - buttonHalf;

    if(prevOrNext === 'next')
      return itemHalf + distanceBetweenItems + buttonHalf;
  }

  const PrevArrowButton = styled.div`
    content: '';
    position: absolute;
    top: 40%;
    right: calc(50% + ${getArrowButtonDistanceForHalf('prev')}px);
    z-index: 1;
    @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      display: none;
    }
  `

  const NextArrowButton = styled.div`
    content: '';
    position: absolute;
    top: 40%;
    right: calc(50% - ${getArrowButtonDistanceForHalf('next')}px);
    z-index: 1;
    @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      display: none;
    }
  `

  return (
    <Wrapper>
      <SliderWrapper>
        <PrevArrowButton onClick={() => sliderRef.current.slickPrev()}>
          <TriangleInCircleButton direction={"right"} color="#222" />
        </PrevArrowButton>
        <NextArrowButton onClick={() => sliderRef.current.slickNext()}>
          <TriangleInCircleButton direction={"left"} color="#222" />
        </NextArrowButton>
        <Slider ref={sliderRef} {...settings}>
          {
            posts.map((post, i) => {
              const member: { name: string, filename: string, pathname: string } = members.find((m) => m.name == post.author);
              return (
                <ArticleSliderCard
                  key={i}
                  frontMatter={post}
                  member={member}
                  marginTop={"40px"}
                />
              )
            })
          }
        </Slider>
      </SliderWrapper>
    </Wrapper>
  )
}

export const PickUpBlog: React.VFC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [posts, setPosts] = useState<WPFrontMatter[]>([]);

  useEffect(() => {
    fetchBlogTopSliderPosts(setPosts, setIsLoading, setHasError);
  }, [])

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          member {
            name
            filename
            pathname
          }
        }
      }
    }
  `)

  const members = site.siteMetadata.member;

  if (hasError) return <ConnectionFailedText />

  return (
    <Node>
      {isLoading ? <LoadingAPI /> : <PickUpArticles posts={posts} members={members} />}
    </Node>
  )
}