import React, { memo, useMemo } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { useAccordion } from "../../../hooks/useAccordion"

import { TriangleSwitch } from "../TriangleSwitch"

const Node = styled.div`
  & .menu-container {
    padding: 0 20px;
  }
  & a {
    text-decoration: none;
  }
`

const Btn = styled.div`
  border-bottom: 1px solid #FFFFFF;
`

const MenuText = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & h2 {
    margin: 0;
    padding: 0;
    font-family: 'Righteous';
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.1em;
    color: #61B1C1;
  }
`

const Ul = styled.ul``
const Li = styled.li`
  & a {
    display: block;
    padding: 8px 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    color: #FFFFFF;
    &:hover {
      color: #61B1C1;
      text-decoration: underline;
    }
  }
`
const Text = styled.span`
  position: relative;
  align-items: center;
  justify-content: space-between;
`

const ArrowWrapper = styled.div`
  transition: 0.3s;
  margin-right: 6px;

  & > span {
    margin: 0;
  }
  .triangle::before,
  .triangle::after {
    margin-left: 5px;
  }
`

type Props = {
  isOpen?: boolean
  title: string
  items: { value: string; slug: string }[]
}

const HeaderAccordion: React.FC<Props> = memo(props => {
  const {
    btnStyle,
    ulStyle,
    liStyle,
    textStyle,
    arrowStyle,
    toggle,
  } = useAccordion()

  const style = useMemo(
    () => ({
      height: props.isOpen ? `100%` : `0`,
      padding: props.isOpen ? `16px 0 16px 0` : `0`,
      visibility: props.isOpen
        ? (`visible` as `visible`)
        : (`hidden` as `hidden`),
    }),
    [props.isOpen]
  )

  const _textStyle = useMemo(
    () => ({
      visibility: props.isOpen
        ? (`visible` as `visible`)
        : (`hidden` as `hidden`),
    }),
    [props.isOpen]
  )

  return (
    <Node>
      <div className="menu-container">
        <Btn className="accordion__btn" onClick={toggle} style={{ ...btnStyle, ...style }}>
          <MenuText>
            <h2>
              {props.title}
            </h2>
            <ArrowWrapper style={arrowStyle}>
              <TriangleSwitch color="#61B1C1" />
            </ArrowWrapper>
          </MenuText>
        </Btn>
      </div>

      <Ul style={ulStyle}>
        {props.items.map((item, i) => {
          return (
            <Li key={i} style={liStyle}>
              <Link to={item.slug} replace>
                <Text style={{ ...textStyle, ..._textStyle }}>
                  {item.value}
                </Text>
              </Link>
            </Li>
          )
        })}
      </Ul>
    </Node>
  )
})

export default HeaderAccordion
