// レスポンシブのスタイル
export const RESPONSIVE_STYLES = {
  // iOS端末画面サイズ一覧
  //   https://qiita.com/tomohisaota/items/f8857d01f328e34fb551
  // MAX_WIDTH <= 画面サイズ
  //   PCになるように対応
  // TB_MAX_WIDTH < 画面サイズ < MAX_WIDTH
  //   可変になるように対応
  // 画面サイズ <= TB_MAX_WIDTH
  //   SPになるように対応
  MAX_WIDTH: 1080, // 元サイズ：1240
  PC_MIN_WIDTH: 1081, // TB_MAX_WIDTH + 1
  TB_MAX_WIDTH: 1080, // 元サイズ：768
  TB_MIN_WIDTH: 521, // SP_MAX_WIDTH + 1
  SP_MAX_WIDTH: 520,

  // その他コンポーネント
  CARD_MAX_WIDTH: 450, // 元サイズ：430
}

export const RESPONSIVE_PADDING = `
  width: 100%;
  box-sizing: border-box;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`

// リクルートページ用のレスポンシブのスタイル
export const RECRUIT_PAGE_STYLES = {
  /** 
   * CASUAL_VISIT_STYLES -> カジュアル面談リンク用
   * CARD_LESPONSIVE_STYLE　-> アイコン入りカードのレスポンシブ用 */ 

  CASUAL_VISIT_STYLES: RESPONSIVE_STYLES.SP_MAX_WIDTH + (RESPONSIVE_STYLES.SP_MAX_WIDTH / 2),

  ICON_CARD_RESPONSIVE_WIDTH: 960,
  POINT_CIRCLE_CARD_WIDTH: 760,
  // 共通マージン
  MARGIN_TOP_STYLE: 
    `margin-top: 96px;
    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      margin-top: 48px;
    }
    `,
  GRID_LAYOUT_RESPONSIVE_WIDTH: 640,
}

// 可変時共通スタイル
export const TABLET_STYLES = `
    @media(min-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) and (max-width: ${RESPONSIVE_STYLES.MAX_WIDTH}px) {
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }
`

export const WRAPPER_STYLES = `
    width: 100%;
    max-width: ${RESPONSIVE_STYLES.MAX_WIDTH}px;
    margin-right: auto;
    margin-left: auto;
    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
        width: 100%;
    }
`

export const POLICY_STYLES = `
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 96px;
  margin: 0 auto 0;
  padding: 96px 0 160px;
  box-sizing: border-box;
  text-align: left;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  & h3 {
    position: relative;
    margin: 0 0 24px;
    padding-left: 26px;
    box-sizing: border-box;
    color: #222222;
    font-size: 2.0rem;
    letter-spacing: 2px;
    &:before {
      content: '';
      position: absolute;
      top: 0.25rem;
      left: 0;
      width: 20px;
      height: 20px;
      background-color: #222222;
    }
  }
  & h4 {
    margin: 48px 0 0;
    color: #222222;
    font-weight: 600;
    font-size: 1.6rem;
    letter-spacing: 1.6px;
  }
  & p {
    margin: 0;
    color: #222222;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.6rem;
    letter-spacing: 1.4px;
  }
  & ul, ol {
    margin: 24px 0 0;
    padding: 0 0 0 3rem;
    box-sizing: border-box;
    color: #222222;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 3.0rem;
    letter-spacing: 1.4px;
  }
  & ul > li > a {
    text-decoration: none;
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding: 0 0 48px 0;
    gap: 80px;
    & h3 {
      margin: 0 0 24px;
      padding-left: 22px;
      font-size: 1.4rem;
      font-weight: 600;
      letter-spacing: 1.4px;
      &:before {
        width: 14px;
        height: 14px;
      }
    }
    & p {
      font-size: 1.4rem;
      line-height: 2.6rem;
      letter-spacing: 1.4px;
    }
    & ul, ol {
      font-size: 1.4rem;
      line-height: 2.6rem;
      letter-spacing: 1.4px;
    }
  }
`

/**
 * お問い合わせ種別（お仕事のご依頼）
 */
export const BusinessInquiryType = [
  "システム開発",
  "WEBサイト制作",
  "実践型プログラマ育成サービス「0→1 ENGINEER CAMP」",
  "技術者支援",
  "その他",
]

/**
 * ご依頼目的（お仕事のご依頼）
 */
export const BusinessPurposeRequest = [
  "概算見積り",
  "お打ち合わせ希望",
  "企画 - 立案補助",
  "その他",
]

/**
 * お問い合わせ種別（パートナーシップ）
 */
export const PartnershipInquiryType = [
  "システムエンジニアリングサービス(SES)について",
  "プロダクト製品の取り扱いについて",
  "その他",
]

/**
 * 都道府県
 */
export const Prefectures = [
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
]

/**
 * 採用関する問い合わせ お問い合わせ内容
 */
export const AskContentTypes = [
  "話を聞きに行きたい(カジュアル面談)",
  "採用に関する質問",
]

/**
 * 採用に関する問い合わせ カジュアル面談の方法
 */
export const AskContactTypes = ["来社", "オンライン"]
export const DATE_TYPE = ["希望なし", "希望日時を選択する"]
export const TIME_TYPE = ["時間指定なし", "午前", "午後"]

export const SERVICE_CONTACT_TYPES = [
  "Web制作・デザイン制作のご相談・お見積り",
  "システム開発・アプリ開発のご相談・お見積り",
  "システム開発支援サービスのご相談・お見積り",
  "「Web勤怠システム オツトメ！」について",
  "「エンジニア育成 0→1 ENGINEER CAMP」について",
  "その他のご依頼・ご相談",
]

export const OTHER_CONTACT_TYPES = [
  "パートナーに関するご相談",
  "採用について",
  "広報・取材等について",
  "ネクストライブについて",
  "その他",
]

/**
 * BLOG カテゴリーとカラーコード
 */
export const BlogCategoryColors = {
  "hr" : "#DDD88C",
  "service-product" : "#DBAAC8",
  "creative" : "#84C17E",
  "recruitment" : "#61B1C1",
  "entertainment" : "#EDB568",
  "information" : "#BDBDBD",
}

export const BlogCategoriesJa = {
  "hr" : "HR・組織づくり",
  "service-product" : "サービス・プロダクト",
  "creative" : "技術・クリエイティブ",
  "recruitment" : "採用",
  "entertainment" : "エンタメ",
  "information" : "お知らせ",
}

/**
 * BLOG カテゴリーごとのPickUpタグ
 */
export const BlogCategoryTags = {
  "hr" : ["コミュニケーション","リモートワーク","業務効率化"],
  "service-product" : ["0→1 ENGINEER CAMP 法人向け", "0→1 ENGINEER CAMP 個人向け", "Web制作"],
  "creative" : ["プログラミング", "デザイン", "Ruby on Rails"],
  "recruitment" : ["インタビュー","福利厚生"],
  "entertainment" : ["ネクストアドベントカレンダー2023", "趣味", "ライフハック", "天下一品"],
  "information" : [],
}

export const SNS_URL = {
  INSTAGRAM: "https://www.instagram.com/nextribe.wonderful",
  TWITTER: "https://twitter.com/nextribe",
  FACEBOOK: "https://www.facebook.com/nextribe.wonderful"
}

/**
 * 検索フォーム用のタグ
 * ToDo:コメントアウトのタグがついた記事が追加されたら、コメントアウト外す
 */
export const SearchTags = [
  "0→1 ENGINEER CAMP 法人向け",
  "0→1 ENGINEER CAMP 個人向け",
  "Web制作",
  // "TEAMLIVE",
  "コミュニケーション",
  "リモートワーク",
  "業務効率化",
  "プログラミング",
  "デザイン",
  "Ruby on Rails",
  "インタビュー",
  "福利厚生",
  "ライフハック",
  "趣味",
  "天下一品",
]

/**
 * WordPressのドメイン
 */
export const OWNEDMEDIA_WORDPRESS_URL = process.env.NODE_ENV === 'production' ? "https://cms.nextribe.co.jp" : "http://localhost:8001";