import React, { useMemo } from "react";
import { merge } from 'lodash';
import styled from 'styled-components';

import white from '../../../images/icon_search_white@2x.svg';
import black from '../../../images/icon_search@2x.svg'

/**
 * Elements & Styles
 */
const Circle = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  background: #61B1C1;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  > div {
    text-align: center;
  }
  & img {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 16px;
    height: 16px;
  }
`

const StaticImg = styled.img``;

/**
 * Type
 */
type Options = {
  flex: string;
  iconColor: 'bk' | 'wh',
  onClick: (() => void) | null
}
type State = { }
type Props = State & Partial<Options>

/**
 * default
 * TODO: #577 iconColorは基本的にbkのみになるので、whは削除予定
 */
const defaultStyle = (): Options => ({
  flex: '16px',
  iconColor: 'bk',
  onClick: null,
});

export const SearchIcon: React.FC<Props> = (props) => {
  const options = useMemo(() => merge(defaultStyle(), {
    flex: props.flex,
    iconColor: props.iconColor,
    onClick: props.onClick,
  }), [
    props.flex,
    props.onClick
  ]);

  const nodeStyle = useMemo(() => ({
    flex: options.flex,
  }), [props.flex]);

  const src = useMemo(() => {
    return options.iconColor == 'bk'
      ? black
      : white
  }, [options.iconColor])

  return (
    <Circle style={nodeStyle} onClick={options.onClick}>
      <StaticImg src={src} alt=""/>
    </Circle>
  );
}