import React from "react"
import styled from "styled-components"

/**
 * Material
 */
import FooterCategoryItem, { CategoryItem } from "./FooterCategoryItem"
import FooterCategoryListItem, { SubCategoryItems } from "./FooterCategoryListItem"

/**
 * Element & Styles
 */
const Wrapper = styled.div`
  width: 17.5%;
`

const SubCategoryWrapper = styled.div`
`

/**
* Props
*/
type State = {
  categoryItem?: CategoryItem;
  subCategoryItems: SubCategoryItems[]
}

type Props = State;

/**
 * Component
 */
export const FooterCategory: React.VFC<Props> = ({ categoryItem, subCategoryItems }) => {
  return (
    <Wrapper>
      {categoryItem ?
        <FooterCategoryItem
          titleEn={categoryItem.titleEn}
          slug={categoryItem.slug}
        /> :
        null
      }
      {subCategoryItems.map((item, index) => {
        return (
          <>
            { /* 01ECのタグリンクを固定で追加し、カテゴリのようにする */}
            {/* { categoryItem.slug === 'blog' && item.slug === 'recommends' && <SubCategoryWrapper>
              <FooterCategoryListItem
                item={"超実践型エンジニア育成"}
                slug={"tags/0%E2%86%921%20ENGINEER%20CAMP/"}
              />
            </SubCategoryWrapper>
            } */}

            <SubCategoryWrapper key={index}>
              <FooterCategoryListItem
                item={item.item}
                slug={item.slug}
              />
            </SubCategoryWrapper>
          </>
        )
      })}
    </Wrapper>
  )
}