import { map } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { FooterCategory } from './FooterCategory'

/**
 * @Styles
 */
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

/**
 * @Types
 */
type Props = {
  data: any
}

export const FooterLinkItems: React.FC<Props> = ({ data }) => {
  const items = ["blogItems", "serviceItems", "recruitItems", "companyItems", "contactItems"]
  return (
    <Wrapper>
      {data.navHeaderItems.map((item, index) => {
        return (
          <FooterCategory
            key={index}
            categoryItem={item}
            subCategoryItems={data[items[index]]}
          />
        )
      })
    }
    </Wrapper>
  )
}
