import React, { useMemo } from "react"
import styled from "styled-components"

import { IMainHeaderItem } from "../../../types/IHeaderItem"
import { SUB_HEADER_MODE } from "./SUB_HEADER_MODE"

/**
 * Element & style
 */
const CategoryWrapper = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  flex-direction: center;
  margin: 0  0 24px 0;
  & h2 {
    margin: 16px 0;
    font-family: 'Righteous';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #61B1C1;
  }
  & p {
    font-size: 16px;
    color: #ffffff;
    margin-left: 24px;
    font-weight: 600;
  }
`

/**
 * Types
 */
type State = {
  mode?: SUB_HEADER_MODE | string;
  mainNavItems: IMainHeaderItem[];
}
type Props = State;

/**
 * ReactComponent
 */
export const SubHeaderTitle: React.FC<Props> = props => {

  const items = useMemo((): IMainHeaderItem | null => {
    // TODO: #577 あとで見直す
    switch (props.mode) {
      case SUB_HEADER_MODE.BLOG:
        return props.mainNavItems[0]
      case SUB_HEADER_MODE.SERVICE:
        return props.mainNavItems[1]
      case SUB_HEADER_MODE.RECRUIT:
        return props.mainNavItems[2]
      case SUB_HEADER_MODE.COMPANY:
        return props.mainNavItems[3]
      case SUB_HEADER_MODE.CONTACT:
        return props.mainNavItems[4]
      case SUB_HEADER_MODE.SEARCH:
        return { titleEn: 'SEARCH', titleJa: '検索', slug: '' }
      default:
        return null
    }
  }, [props.mode]);

  if (!items) {
    return null;
  }

  return (
    <CategoryWrapper>
      <h2>{items?.titleEn}</h2>
      <p>{items?.titleJa}</p>
    </CategoryWrapper>
  )
}
