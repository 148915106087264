import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { RESPONSIVE_PADDING, RESPONSIVE_STYLES, WRAPPER_STYLES } from '../../../AppConst'
import { TriangleInCircleButton } from '../../common/TriangleInCircleButton'

const Node = styled.div`
  background-color: #222;
  width: 100%;
  min-width: ${RESPONSIVE_STYLES.MAX_WIDTH}px;
  transition: 0.3s;
  overflow: hidden;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    min-width: auto;
  }
  &:hover {
    background-color: #393939;
    & .outside-circle {
      -webkit-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -ms-transform: scale(1.5);
      transform: scale(1.5);
    }
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const Wrapper = styled.div`
  ${WRAPPER_STYLES}
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 90px 0;
  box-sizing: border-box;
  & .title-container {
    display: flex;
    align-items: center;
    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      display: block;
    }
  }
  & .section-title {
    font: normal normal normal 4.8rem/5.9rem Righteous;
    letter-spacing: 4.8px;
    color: #fff;
    margin-right: 32px;
    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      font: normal normal normal 3.2rem/2.6rem Righteous;
      letter-spacing: 3.2px;
      display: block;
      margin-right: 0px;
      margin-bottom: 8px;
    }
  }
  & .message {
    font-size: 1.6rem;
    font-weight: 600;
    letter-spacing: 1.6px;
    color: #fff;
    opacity: 0.5;
    line-height: 5.9rem;
    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      font-size: 1rem;
      letter-spacing: 1px;
      line-height: 1.0rem;
    }
  }
  @media(max-width: ${RESPONSIVE_STYLES.MAX_WIDTH}px) {
    padding-top: 96px;
    padding-bottom: 96px;
  }
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  ${RESPONSIVE_PADDING}
`

const ButtonWrapper = styled.div`
  content: '';
  position: absolute;
  transform: rotate(300deg);
  right: 13px;
`

export const Contact:React.VFC = () => {
  return (
    <Node>
      <StyledLink to='/contact'>
        <Wrapper>
          <div className='title-container'>
            <span className='section-title'>CONTACT</span>
            <span className='message'>お気軽にお問い合わせください</span>
          </div>
          <ButtonWrapper>
            <TriangleInCircleButton direction='right' />
          </ButtonWrapper>
        </Wrapper>
      </StyledLink>
    </Node>
  )
}
