import React, { memo, useMemo } from 'react';
import styled from 'styled-components';

const Node = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: #222;
  border-radius: 50%;
  border: ${({ isOpen }) => isOpen ? `solid 1px #61B1C1` : `solid 1px transparent` };
`
const Line = styled.div`
  position: absolute;
  height: 2px;
  width: 40%;
  background-color: #61B1C1;
  border-radius: 2px;
  transition: 0.5s;
`

type Props = {
  isOpen: boolean
  onClick: ((e: React.MouseEvent) => void | null)
}

export const HamburgerBtn: React.FC<Props> = memo((props) => {
  const topStyle = useMemo(() => ({
    top: props.isOpen ? `50%` : `40%`,
    transform: props.isOpen ? `rotate(45deg)` : ``
  }), [
    props.isOpen
  ])

  const middleStyle = useMemo(() => ({
    top: `50%`,
    opacity: props.isOpen ? 0 : 1
  }), [
    props.isOpen
  ])

  const bottomStyle = useMemo(() => ({
    top: props.isOpen ? `50%` : `60%`,
    transform: props.isOpen ? `rotate(-45deg)` : ``
  }), [
    props.isOpen
  ])

  return (
    <Node onClick={props.onClick} isOpen={props.isOpen}>
      <Line style={topStyle}></Line>
      <Line style={middleStyle}></Line>
      <Line style={bottomStyle}></Line>
    </Node>
  );
})
